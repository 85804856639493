import Vue from 'vue'

// A modern alternative to CSS resets
// https://github.com/necolas/normalize.css
import 'normalize.css/normalize.css'
import './styles/index.scss'
import './assets/iconfont/iconfont.css'

import App from './App.vue'
import router from './router'
import store from './store'
import Login from './views/auth/login'
import Register from './views/auth/registry'

import './icons'
import './permission'
import i18n from './i18n/i18n'
// import vant
import '@/components/Vant'
// import fontAwesome
import '@/components/fontAwesome'
// register common components globally
import '@/components/common'
import filters from '@/filter'
import Bus from './eventBus'
// 引入fastclick
// import FastClick from 'fastclick'
Vue.prototype.$bus = Bus
// FastClick.attach(document.body)
import VueClipboard from 'vue-clipboard2'
import 'swiper/css/swiper.min.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import { fpjsPlugin } from '@fingerprintjs/fingerprintjs-pro-vue-v2'
Vue.use(fpjsPlugin, {
  loadOptions: {
  //rr  apiKey: 'Sfcdn9HDlm6EVzNx2INd',
   // cw  apiKey: 'q66dst1ZdwlN3sceb0zg',

  //jb   apiKey: 'mbdiz2SurfiHG0y8f0Ja',
apiKey: 'q66dst1ZdwlN3sceb0zg',
    region: 'ap'
  }
})

Vue.component('Swiper', Swiper)
Vue.component('SwiperSlide', SwiperSlide)
Vue.use(VueClipboard)
Vue.use(filters)
Vue.component('Login', Login)
Vue.component('Register', Register)
import VueSocketIO from 'vue-socket.io'

// import Popover from 'vant'
// Vue.use(Popover)

Vue.use(new VueSocketIO({
  // 控制台调试 console.log
  debug: true,
  options: {
    autoConnect: false
  },
  // 连接地址 后端提供
  // connection: 'http://localhost:8878'
  connection: process.env.VUE_APP_BASE_API + '/'
  // vuex，不需要则不加
  // vuex: {
  //   store,
  //   mutationPrefix: "SOCKET_",
  //   actionPrefix: "SOCKET_"
  // }
}))
// mock in online
if (process.env.NODE_ENV === 'production') {
  // const { mockXHR } = require('../mock')
  // mockXHR()
}

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register(`/sw.js?${Date.now()}`).then(registration => {
//       console.log('SW registered: ', registration)
//     }).catch(registrationError => {
//       console.log('SW registration failed: ', registrationError)
//     })
//   })
// }
// 禁用双指放大
document.documentElement.addEventListener('touchstart', function(event) {
  if (event.touches.length > 1) {
    event.preventDefault()
  }
}, {
  passive: false
})

// 禁用双击放大
var lastTouchEnd = 0
document.documentElement.addEventListener('touchend', function(event) {
  var now = Date.now()
  if (now - lastTouchEnd <= 300) {
    event.preventDefault()
  }
  lastTouchEnd = now
}, {
  passive: false
})
Vue.config.productionTip = false
Vue.prototype.bus = new Vue() // EventBus事件中转  add by lau

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
